.visits-toolbar {
    margin-top: 2em;

    h2 {
        flex-grow: 1;
        margin-left: 16%;
    }

    a {
        max-width: 20%;
    }
}

.visits-today {
    tr th {
        background-color: $blue;
    }
}

.visits-other {
    tr th {
        background-color: $light-grey;
    }
}

@media (max-width: $mobile-up) {
    table th:nth-child(2), table th:nth-child(3), table th:nth-child(4) {
        display: none;
    }

    table td:nth-child(2), table td:nth-child(3), table td:nth-child(4) {
        display: none;
    }
}


.detail-btn {
    margin-bottom: unset;
    margin-left: 17px;
}