// colors
$blue: #0094c5;
$white: #ffffff;
$yellow: #fdc300;
$green: #3eb33e;
$light-grey: #c7c7c7;
$grey: #6a6a6a;
$dark-grey: #373737;
$blue-grey: #98a4b4;
$red: #e82929;

// function to create colortints
@function tintDark($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@function tintLight($color, $percentage) {
  @return mix(white, $color, $percentage);
}

// viewports
$mobile-up: 767px;
$medium-up: 1029px;
