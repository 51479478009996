.upload-photo-module {
    max-width: 170px;
}

.upload-photo-wrapper {
    &.multiply {
        display: flex;
        flex-wrap: wrap;

        .upload-photo-container {
            width: 52px;

            &:first-child {
                width: 100%;
                height: 70px;
                margin-bottom: 6px;

                .upload-photo-remove {
                    left: 100%;
                    transform: translate(-60%, -40%);
                }
            }
        }

        .upload-button {
            width: 52px;
            height: 54px;
            padding: 0;
            margin: 2px;
            border: 2px dashed $light-grey;

            svg {
                height: 22px;
                border: none;
                color: $yellow;
            }
        }
    }

    .upload-photo-container {
        position: relative;
        width: 100%;
        height: 52px;
        padding: 2px;

        .upload-photo-remove {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 75%);
            width: 22px;
            height: 22px;
            margin: 0;
            background-color: $white;
            color: $red;
            border-radius: 50%;
            cursor: pointer;
        }

        .upload-photo {
            height: inherit;
            width: inherit;
            object-fit: cover;
            border-radius: 6px;
            border: 1px solid $light-grey;
        }
    }
}
