table {
    border-collapse: collapse;
    width: 100%;
    color: $grey;
    font-size: 1.2em;
    margin-bottom: 1.8em;
}

table td, table th {
    border: none;
    padding: 0.7rem;
}

table tr:nth-child(even){
    background-color: tintLight($blue, 85%);
}

table tr:hover {
    color: $white;
    background-color: tintLight($blue, 50%);
    cursor: pointer;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: $blue;
    color: $white;
}
