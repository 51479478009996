.style-picker-btn {
  display: inline;
  margin-top: 80px;
  padding: 10px;
  color: #000;
}

.color-display {
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 200px;
  border-radius: 11px;
}

.color-display .chrome-picker {
  margin-left: 210px;
  margin-top: -19px;
}

.logo-picker {
  display: flex;
  justify-content: center;
}


.logo-update{
  width: 260px;
    height: 260px;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: contents;
}

.upload-photo-remove{
  position: absolute;
    left:64%;
    transform: translate(-50%, 75%);
    width: 22px;
    height: 22px;
    margin: 0;
    background-color: #ffffff;
    color: #e82929;
    border-radius: 50%;
    cursor: pointer;
}

.button-update-theme{
  padding-left: 35%;
  padding-top: 5%;

}

// hover effect to the profile image
.container-hov {
  position: relative;
  max-width: 100%;
}
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container-hov:hover .upload-photo {
  opacity: 0.3;
  color: #0094c5;
}

.container-hov:hover .middle {
  opacity: 1;
}

.text {
   color: #0094c5;
  font-size: 16px;
  padding: 16px 32px;
}


