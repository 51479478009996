.design-module-preview {
    object-fit: contain;
    border: 1px solid $light-grey;
    margin-bottom: 1.8em;
}

.design-module-options {
    padding-left: 1em;

    button {
        width: 100%;
    }
}

.design-module-wrapper {
    position: fixed;
    top: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    iframe {
        width: 100%;
        height: calc(90vh - 0.5em);
        border: none;
    }

    .container {
        background-color: $white;
    }

    .design-module-tools {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
        padding: 1em 0;
        height: calc(10vh - 2em);
    }
}

.cursorP {
    cursor: pointer;
}