.calculation-table-wrapper {
    .calculation-table {
        border-collapse: collapse;
        width: 100%;
        color: $grey;
        font-size: 1.1em;
        font-weight: 300;
        margin-bottom: 1.8em;

        td, table th {
            border: none;
            padding: 0;
        }

        tr {
            pointer-events: none;

            td {
                margin: 0.2rem 0;
            }

            &.total-row {
                margin-top: 0.4rem;
                font-weight: 400;
                border-top: 1px solid $grey;

                td {
                    margin-top: 0.5rem;
                }
            }

            &:nth-child(odd) {
                background-color: $white;
            }

            &:nth-child(even) {
                background-color: $white;
            }

            td:last-child {
                float: right;
            }

            td:first-child {
                float: left;
            }
        }
    }

    button {
        width: 100%;
    }
}

