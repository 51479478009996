button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    margin-bottom: 1.8em;
    color: $white;
    border-radius: 6px;
    outline: none;
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    font-weight: bold;

    &.right {
        flex-direction: row-reverse;

        svg {
            margin-left: 0.6em;
            margin-right: 0;
        }
    }

    svg {
        margin-top: -2px;
        margin-right: 0.6em;
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    &.small {
        padding: 0.5em 2em;
    }

    &.medium {
        padding: 1em 2.5em;
    }

    &.large {
        padding: 1em 3em;
    }

    &.green {
        background-color: $green;

        &:hover:not([disabled]) {
            background-color: tintDark($green, 20%);
        }
    }

    &.yellow {
        background-color: $yellow;

        &:hover:not([disabled]) {
            background-color: tintDark($yellow, 20%);
        }
    }

    &.blue {
        background-color: $blue;

        &:hover:not([disabled]) {
            background-color: tintDark($blue, 20%);
        }
    }

    &.upload-button {
        display: flex;
        flex-direction: column;
        padding: 1.5em 3em;
        background-color: $white;
        color: $light-grey;
        border: 1px solid $light-grey;
        justify-content: center;
        align-items: center;
        font-weight: 500;

        svg {
            margin: 0 0 0.3em 0;
            font-size: 2.5em;
        }
    }
}

.navigation-buttons {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
}

svg.button {
    cursor: pointer;
}

@media (min-width: $mobile-up) {
    button {
        width: auto;
    }

    .navigation-buttons {
        margin-top: 1.5em;
        display: flex;
        justify-content: space-evenly;
    }
}
