// .td-text-align {
//     text-align: center;
// }

// .td-align-item {
//     vertical-align: middle;
//     text-align: center;
// }

// .th-align-text th{
//     text-align: center;
// }

.td-text-align {
  text-align: left;
  font-size: 15px;
}

.td-align-item {
  vertical-align: middle;
  text-align: center;
}

.th-align-text th {
  text-align: center;
}
.list-1 table td,
table th {
  font-size: 15px !important ;
  text-align: left;
}
.list-1 input {
  width: 100%;
  padding: 2px;
  border: solid 1px #ccc;
}
.list-1 th {
  font-size: 15px !important ;
  text-align: left;
}
// .visits-toolbar h2 {text-align: center;  margin: 0 auto; margin-bottom:20px;}

.mr10 {
  margin: 10px;
}

#userDetailTab  .pdf-file-dimentions{
  width: 707px;
  height: 850px;
}


div#user-detail-popup  input{
  pointer-events: none;
}