.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .popup-wrapper {
        height: 100vh;
        align-items: center;
    }

    
    .popup-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        border: 1px solid $light-grey;
        border-radius: 6px;
        background-color: $white;
    }

    // custom v-scrollpdf 

    .v-scrollpdf{
        height: 500px;
        overflow-y: scroll;
    }
   

    .popup-sign {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        border: 1px solid $light-grey;
        border-radius: 6px;
        background-color: $white;

        .popup-top {
            margin-left: 0;
        }

        .popup-sign-column {
            padding: 1em;
            width: 49%;

            .popup-sign-canvas {
                border: 1px solid $light-grey;
                margin-bottom: 1.8em;
            }

            .popup-sign-delivery {
                font-weight: 300;

                span {
                    color: $blue;
                    font-weight: bold;
                    margin-right: 4px;
                }
            }

            button {
                width: 100%;
            }
        }
    }

    .popup-message {
        margin-bottom: 1em;
        padding: 0 20px;
    }

    .popup-photo-upload {
        margin-bottom: 1em;
        padding: 0 20px;
        width: 80%;
    }

    .popup-buttons {
        width: 90%;
    }

    button {
        margin: 0 0 8px 0;
    }

    .popup-top {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        margin-top: -20px;
        margin-left: -20px;
        margin-right: -20px;
    }

    .popup-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        font-size: 1.5em;
        color: $grey;
    }
}
