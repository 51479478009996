.uiloader {
    display: none;
}

.preloader-container {
    position: fixed;
    top: calc(50% - (170px / 2));
    left: calc(50% - (200px / 2));
    width: 200px;
    height: 170px;
    background-color: #FFFFFF;
    z-index: 9999999 !important;
}

.preloader-image {
    width: 100%; 
    height: 100%; 
    background-image: url("https://www.sunny4solar.nl/wp-content/uploads/2021/09/Sunny4Solar-_-03-01.png") !important;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

.preloader-image .preloader-progress {
    background-color: #FFFFFF;
    opacity: 0.8;
    width: 100%;
}