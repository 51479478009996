.e-text-content {
  border: 1px solid #00000040 !important;
  padding: 10px !important;
  min-width: 170px !important;
}
.e-text-content:hover {
  background-color: #eee !important;
  border-color: #eee !important;
  height: 19% !important;
}

#waitcategory {
  color: #00000099;
}

#waitlist {
  font-weight: 400;
  font-size: 15px;
}

.e-treeview .e-text-content,
.e-treeview .e-fullrow {
  border-color: #fff;
  width: 0% !important;
}

.title {
  padding-left: 6px;
}

.popup-date-picker {
  display: flex;
}

.e-treeview .e-ul {
  max-height: 750px;
}

.title-text.header-align {
  margin-left: 53px;
}

.popup-header {
  text-align: center;
  margin-left: 160px;
  display: inline-flex;
}

.popup-header span {
  display: block;
  margin-left: 10px;
}

/* collabrator */
.just-to-show {
  color: #3a87ad;
  text-align: center;
  width: 100%;
  vertical-align: baseline;
  font-size: 119%;
}



.svg-inline--fa.fa-w-20 {
  font-size: 37px;
}

/* events css */
.events {
  color: #3a87ad;
  text-align: center;
  width: 100%;
  vertical-align: baseline;
  font-size: 119%;
}



.svg-inline--fa.fa-w-14 {
  font-size: 37px;
}
/* Leads */

.leads {
  color: #3a87ad;
  text-align: center;
  width: 100%;
  vertical-align: baseline;
  font-size: 119%;
}

.tr-leads:before {
    content: "";
    display: block;
    width: 164px;
    height: 2px;
    text-align: center;
    position: relative;
    background: #3a87ad;
  
    left: 1px;
    top: 26px;
    z-index: 2;
}
.tr-leads:after {
    content: "";
    display: block;
    width: 157px;
    height: 2px;
    text-align: center;
    position: relative;
    background: #3a87ad;
    right: -268px;
    top: -13px;
    z-index: 2;
}

table tr:hover {
  color: unset;
  background-color: unset;
  cursor: unset;
}

table tr:nth-child(even) {
  background-color: unset;
}
.e-title-text {
  color: #3a87ad;
}

.lead-border{
  display: block;
  width: 100%;
  height: 2px;
  text-align: center;
  position: absolute;
  background: #3a87ad;
  left: 1px;
  top: 50%;
  z-index: 0;
}

.lead-tr, .leads {
  position: relative;
}

.lead-tr {
  text-align: center;
}

.leads {
  z-index: 99;
  display: inline-block;
  width: fit-content;
    background: white;
}

.bg-color-white {
  background-color: white;
}