.visit-plan-get-info {
  margin-top: 1em;

  .input-wrapper {
    padding-right: 1em;
    width: 100%;
  }

  button {
    width: 100%;
  }

  p {
    color: $grey;
    text-align: center;
    margin: -18px 0 0 0;
  }
}

.section-logo {
  justify-content: center;
  flex-direction: column-reverse;
}

.quote-pdf {
  height: 600px;
  // overflow: scroll;
  overflow: hidden;
  border: 1px solid $light-grey;
  border-top: 4px solid $blue;
}

.react-pdf__Page__canvas {
  height: 100% !important;
  width: 100% !important;
  border-bottom: 1px solid $light-grey;

  > canvas {
    height: 100% !important;
    width: 100% !important;
  }
}

@media (min-width: $mobile-up) {
  .section-logo {
    justify-content: initial;
    flex-direction: initial;

    h2 {
      flex-grow: 1;
      margin-left: 250px;
    }
  }
}

.visit-pdf-download {
  float: right;
  margin-right: 5px;
  font-size: 1.2em;
  text-align: left;
  color: #0094c5;
  font-weight: 700;
}

.display-inline {
  display: inline;
}

.display-flx {
  display: flex;
}

.mL-13 {
  margin-left: 13px !important;
}
.mT-135{
  margin-top:135px;
}


.pdf-file-dimentions{
  width:877px;
  height:1000px;
}