.tabs-buttons {
    display: flex;

    .tab-button {
        font-size: 1.1em;
        color: $grey;
        cursor: pointer;
        background-color: $white;
        border-right: 1px solid $light-grey;
        border-top: 1px solid $light-grey;
        padding: 1em 2.5em;

        &:first-child {
            border-left: 1px solid $light-grey;
            border-top-left-radius: 6px;
        }

        &:last-child {
            border-top-right-radius: 6px;
        }

        &.tab-button--active {
            background-color: $blue;
            color: $white;
        }
    }
}

.tabs-content {
    padding: 2em 2em 1em 2em;
    border-top: 3px solid $blue;
    border-bottom: 1px solid $light-grey;
    border-left: 1px solid $light-grey;
    border-right: 1px solid $light-grey;

    .tab-item {
        display: none;

        &.tab-item--active {
            display: block;
        }
    }
}
