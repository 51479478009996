.product-choise-wrapper {
    padding-right: 1em;

    .product-choise-selection {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        img {
            width: 20%;
            object-fit: contain;
        }

        .select-wrapper {
            width: 75%;
            margin: 0;
        }
    }

    .product-choise-specifications {
        h4 {
            font-size: 1.2em;
            color: $blue;
            font-weight: normal;
            margin-bottom: 0.5em;
        }

        table {
            border-collapse: collapse;
            width: 100%;
            color: $grey;
            font-size: 0.8em;
            margin-bottom: 1.8em;

            td, table th {
                border: none;
                padding: 0.7rem;
            }

            tr {
                pointer-events: none;

                &:nth-child(odd) {
                    background-color: tintLight($light-grey, 85%);
                }

                &:nth-child(even) {
                    background-color: $white;
                }

                td {
                    width: 70%;
                }

                td:first-child {
                    width: 30%;
                    font-weight: bold;
                }
            }
        }

        .product-choise-stock__normal {
            color: $green;
            font-weight: bold;
            margin-bottom: 6em;

            svg {
                margin-right: 4px;
            }
        }

        .product-choise-stock__low {
            color: $yellow;
            font-weight: bold;
            margin-bottom: 6em;

            svg {
                margin-right: 4px;
            }
        }
    }
}
