

.sidebar {
    position: fixed;
    height: 100%;
    z-index: 2;
    left: 0;
    color: $white;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .sidebar-menu {
        height: 100vh;
        width: 100vw;
        background-color: $blue;

        a {
            text-decoration: none;

            &:first-of-type div {
                border-top: 1px solid $white;
            }
        }

        .sidebar-menu-item {
            padding: 1em 2em;
            border-bottom: 1px solid $white;
            color: $white;
            //font-size: 1.2em;
            font-size: 0.9em;

            &:hover {
                background-color: tintDark($blue, 20%);
            }

            svg {
                margin-left: -0.6em;
                min-width: 1.5em;
            }

            span {
                margin-left: 1em;
            }
        }
    }

    .sidebar-top {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 2em;

        svg {
            font-size: 1.9em;
            margin-top: -6px;
        }

        h4 {
            font-size: 1.1em;
            flex-grow: 1;
            text-align: center;
        }
    }
}

@media (min-width: $mobile-up) {
    .sidebar {
        .sidebar-menu {
            width: 35vw;
        }
    }
}

@media (min-width: $medium-up) {
    .sidebar {
        .sidebar-menu {
            width: 30vw;
            position: relative;
        }
    }
    .bottom-navigation {
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}
