@media(max-width:1024px){

.home-1 button.small {padding: 0.5em 1em;}
.home-1 .visits-toolbar {display: block ;}
.navbar .navbar-control  {padding-left: 3%;}
.navbar-goBack  {padding-right: 3%;}
.home-1 .visits-toolbar a { flex: content;}
.home-1 .visits-toolbar h2 {margin-left: 20%;}
.list-1 h2 { width: 100%; margin-left: 0;}
.visit-plan h2 { max-width: 55%; margin-left: 0;}
.setting button { width: 100%;}

#user-detail-popup .tabs-content .pdf-file-dimentions {
    width: 738px;
    height: 630px;
}
}

@media(max-width:991px)
{

}

@media(max-width:768px)
{
    .home-1 h2 {width:65% !important; }
    .visit-plan h2 { max-width: 58%; margin-left: 0;}
     table td, table th { font-size: 12px;}
     .home-1 button.small { font-size: 12px; display: block;}
     #user-detail-popup .tabs-content {
         padding: 0;
     }

     #user-detail-popup .tabs-content .pdf-file-dimentions {
        width: 588px !important;
        height: 630px !important;
    }
}
