.progress-bar-wrapper {
    width: 100%
}

.progress-bar {
    display: flex;
    justify-content: space-evenly;
    padding: 0;
}

.progress-bar li {
    list-style-type: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    color: $grey;
}

.progress-bar li:before {
    content: " ";
    line-height: 30px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 2px solid $light-grey;
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background-color: white
}

.progress-bar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $light-grey;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.progress-bar li:first-child:after {
    content: none;
}

.progress-bar li.active:before {
    border-color: $blue;
    background-color: $blue;
}

.progress-bar .active:after {
    background-color: $blue;
}
