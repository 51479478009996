html {
    overflow: scroll;

    &.overlay {
        overflow: hidden;
    }
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin: 0;
}

h2 {
    text-align: center;
    color: $blue;
    font-weight: 700;
}

h3 {
    font-size: 1.2em;
    text-align: left;
    color: $blue;
    font-weight: 700;
    margin: 0.8em 0;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;

    &:hover, &visited {
        text-decoration: none;
    }
}

.main-content {
    min-height: 100%;
    margin: 0 auto -1em;
}

.container {
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto;
}

.topheader {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $blue;
    color: $white;
}

.navbar {
    height: 56px;

    .navbar-control {
        font-size: 1.4em;
        flex-grow: 1;
    }

    .navbar-title {
        flex-grow: 1;
        margin-left: -20px;

        h3 {
            font-weight: 400;
            color: $white;
        }
    }
    .navbar-goBack svg{
        height: 2em;
        width: 2em;
    }
}

.section-logo {
    padding: 0.5em 0;

    img {
        max-width: 250px;
    }

    .address-title {
        svg {
            margin-right: 6px;
        }
    }
}

.login-logo {
    padding: 0.5em 0;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.section-footer--line {
    height: 1em;
    background-color: $blue;
}

.section-footer-image {
    width: 100%;
    height: fit-content;
    object-fit: cover;
    margin-bottom: -4px;
}

@media screen and (min-width: 1024px) {
    .container {
        max-width: 960px;
    }
}

@media screen and (min-width: 1216px) {
    .container {
        max-width: 1152px;
    }
}

@media screen and (min-width: 1408px) {
    .container {
        max-width: 1344px;
    }
}
