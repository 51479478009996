label, input {
    transition: all 0.2s;
    touch-action: manipulation;
}

input {
    font-size: 1.3em;
    border: 0;
    color: $dark-grey;
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
    cursor: text;
    background-color: $white;
}

input:disabled,
input:read-only,
input[type="radio"]:disabled + label {
    opacity: 0.4 !important;
    cursor: not-allowed !important;
}

input:focus {
    outline: 0;
}

label {
    .mandatory {
        color: $red;
    }
}

.input-row .input-wrapper,
.input-row .range-wrapper,
.input-row .select-wrapper,
.input-row .radio-wrapper,
.input-row .checkbox-wrapper {
    width: 45%;
}

.input-row.summary .input-wrapper {
    &:first-child {
        width: 70%;
    }

    &:last-child {
        width: 20%;
    }
}

.input-wrapper {
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 1.8em;

    input {
        border-bottom: 1px solid $light-grey;
    }

    input:placeholder-shown + label {
        cursor: text;
        max-width: 66.66%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 1rem) scale(1.3);
        color: $light-grey;
    }

    input::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
    }

    input:not(:placeholder-shown) + label,
    input:focus + label {
        transform: translate(0, 0) scale(1);
        cursor: pointer;
        color: $blue;
    }

    input[type="date"] {
        width: 100%;
        margin-top: -4px;
    }
}

.range-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.8em;
    padding: 0.5em 0;

    label {
        font-size: 1.3em;
        color: $grey;
        max-width: 30%;
        width: 25%;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    input[type="number"] {
        border-bottom: 1px solid $light-grey;
        width: 10%;
        margin-left: 0.5em;
    }

    input[type="range"] {
        background: linear-gradient(to right, $blue 0%, $blue 50%, $light-grey 50%, $light-grey 100%);
        border-radius: 8px;
        height: 2px;
        width: 65%;
        outline: none;
        transition: background 450ms ease-in;
        -webkit-appearance: none;
        cursor: pointer;
    }

    input[type="range"]::-webkit-slider-thumb {
        background-color: $blue;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        -webkit-appearance: none;
        cursor: pointer;
        border: none;
    }
}

.select-wrapper {
    display: flex;
    flex-flow: column;
    margin-bottom: 1.8em;
    border-bottom: 1px solid $light-grey;

    label {
        font-size: 1em;
        color: $blue;
    }

    select {
        font-size: 1.2em;
        height: 24px;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        outline: none;
        background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iZHJvcGRvd24iIGRhdGEtbmFtZT0iZHJvcGRvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE0IiBoZWlnaHQ9IjE0IiB2aWV3Qm94PSIwIDAgMTQgMTQiPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojOGU4ZThlO308L3N0eWxlPjwvZGVmcz48cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTIuMjQgNC4zMyA3IDEwLjU2IDEuNzYgNC4zMyAxMi4yNCA0LjMzIi8+PC9zdmc+');
        background-position: right .5em center;
        background-repeat: no-repeat;
        padding-right: 1.8em;
        background-color: $white;
    }

    &.row {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        border-bottom: 0;

        label {
            font-size: 1.3em;
            color: $grey;
            width: 35%;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        select {
            font-size: 1.3em;
            border-bottom: 1px solid $light-grey;
            width: 100%;
        }
    }
}

.radio-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.8em;
    align-items: center;

    .radio-tabs {
        margin-top: 0.4em;
    }

    label:first-child {
       font-size: 1.3em;
        color: $grey;
        max-width: 30%;
        width: 25%;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    input {
        display: none;

        & + label {
            cursor: pointer;
            float: left;
            border-right: 2px solid $white;
            background-color: $blue-grey;
            color: $white;
            margin-right: -1px;
            padding: 0.5em 1.5em;
            position: relative;
            font-style: italic;
            font-weight: 300;

            &:first-of-type {
                border-radius: 6px 0 0 6px;
            }

            &:last-child {
                border-radius: 0 6px 6px 0;
            }
        }

        &:checked + label {
            background-color: $blue;
            color: $white;
            font-style: normal;
            font-weight: normal;
        }
    }
}

.checkbox-wrapper {
    display: block;
    position: relative;
    padding-left: 2em;
    margin-bottom: 1.8em;
    cursor: pointer;
    user-select: none;
    font-size: 1.3em;
    color: $grey;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark:after {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border: 1px solid $blue;
        border-radius: 6px;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 2px;
            width: 5px;
            height: 15px;
            border: solid $blue;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }
}

.upload-wrapper {
    width: 100%;
    background-color: $light-grey;
    border: 1px solid $light-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    margin-bottom: 1.8em;

    input[type="file"] {
        height: 0;
        overflow: hidden;
        width: 0;
    }

    input[type="file"] + label {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        border: none;
        color: $grey;
        cursor: pointer;
        outline: none;
        position: relative;
        vertical-align: middle;
        font-weight: bold;

        &.show-preview {
            display: none;
        }

        svg {
            margin-bottom: 0.3em;
            font-size: 3em;
        }
    }

    .upload-preview {
        display: none;
        position: relative;
        height: 100%;
        width: 100%;

        &.show-preview {
            display: block;
        }

        .upload-remove {
            cursor: pointer;
            font-size: 2em;
            color: $red;
            position: absolute;
            top: 0;
            right: 0;
            margin-top: -10px;
            background-color: white;
            margin-right: -10px;
            border-radius: 50%;
            border: none;
        }

        img {
            width: inherit;
            object-fit: cover;
            height: inherit;
        }
    }
}

.input-table-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.8em;

    .input-wrapper {
        margin: 0 10px 0 0;
        width: 20%;

        &:first-child {
            width: 53%;
        }
    }

    .input-table-remove {
        cursor: pointer;
        flex-grow: 1;
        color: $grey;
    }
}

.text-wrapper {
    display: flex;
    flex-flow: column;
    margin-bottom: 1.8em;
    margin-right: 1.1em;

    label {
        font-size: 1em;
        color: $blue;
        margin-bottom: 4px;
    }

    textarea {
        border: 1px solid $light-grey;
        width: 100%;
        max-width: 100%;
        height: 150px;
        min-height: 150px;
        font-size: 1.3em;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        padding: 8px;
        resize: none;
        outline: none;

        &::placeholder {
            color: $light-grey;
        }
    }
}
