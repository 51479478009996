.row:after,
.row:before,
.row-padding:after,
.row-padding:before {
    content: "";
    display: table;
    clear: both;
}

.row-padding,
.row-padding > .half,
.row-padding > .third,
.row-padding > .twothird,
.row-padding > .threequarter,
.row-padding > .quarter,
.row-padding > .column {
    padding: 0 1em;
}

.flex-start {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.flex-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.flex-space-evenly {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.flex-space-between {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flex-align-center {
    align-items: center;
}

.flex-align-baseline {
    align-items: baseline;
}

.flex-align-start {
    align-items: flex-start;
}

.flex-direction-column {
     flex-direction: column;
}

.flex-direction-row {
     flex-direction: row;
}

.column,
.half,
.third,
.twothird,
.threequarter,
.quarter {
    float: left;
    width: 100%;
}

.column.rest {
    overflow: hidden;
}

.column.small-1 {
    width: 8.33333%;
}

.column.small-2 {
    width: 16.66666%;
}

.column.small-3 {
    width: 24.99999%;
}

.column.small-4 {
    width: 33.33333%;
}

.column.small-5 {
    width: 41.66666%;
}

.column.small-6 {
    width: 49.99999%;
}
.column.small-7 {
    width: 58.33333%;
}

.column.small-8 {
    width: 66.66666%;
}

.column.small-9 {
    width: 74.99999%;
}

.column.small-10 {
    width: 83.33333%;
}

.column.small-11 {
    width: 91.66666%;
}

.column.small-12 {
    width: 99.99999%;
}

@media (min-width: $mobile-up) {
    .flex-start {
        flex-wrap: nowrap;
    }

    .flex-end {
        flex-wrap: nowrap;
    }

    .flex-center {
        flex-wrap: nowrap;
    }

    .flex-space-evenly {
        flex-wrap: nowrap;
        justify-content: space-evenly;
    }

    .column.medium-1 {
        width: 8.33333%;
    }

    .column.medium-2 {
        width: 16.66666%;
    }

    .column.medium-3,
    .quarter {
        width: 24.99999%;
    }

    .column.medium-4,
    .third {
        width: 33.33333%;
    }

    .column.medium-5 {
        width: 41.66666%;
    }

    .column.medium-6,
    .half {
        width: 49.99999%;
    }

    .column.medium-7 {
        width: 58.33333%;
    }

    .column.medium-8,
    .twothird {
        width: 66.66666%;
    }

    .column.medium-9,
    .threequarter {
        width: 74.99999%;
    }

    .column.medium-10 {
        width: 83.33333%;
    }

    .column.medium-11 {
        width: 91.66666%;
    }

    .column.medium-12 {
        width: 99.99999%;
    }
}

@media (min-width: $medium-up) {
    .column.large-1 {
        width: 8.33333%;
    }

    .column.large-2 {
        width: 16.66666%;
    }

    .column.large-3 {
        width: 24.99999%;
    }

    .column.large-4 {
        width: 33.33333%;
    }

    .column.large-5 {
        width: 41.66666%;
    }

    .column.large-6 {
        width: 49.99999%;
    }

    .column.large-7 {
        width: 58.33333%;
    }

    .column.large-8 {
        width: 66.66666%;
    }

    .column.large-9 {
        width: 74.99999%;
    }

    .column.large-10 {
        width: 83.33333%;
    }

    .column.large-11 {
        width: 91.66666%;
    }

    .column.large-12 {
        width: 99.99999%;
    }
}
